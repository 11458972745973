import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { jwtOptionsFactory } from './core/helpers/tokenGetter.helper';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AppCommonModule } from './core/shared/app-common/app-common.module';
import { SnackBarLoaderComponent } from './core/shared/app-common/snack-bar-loader/snack-bar-loader.component';
import { InitializerService, initProviderFactory } from 'src/app/core/services/initializer/initializer.service';
import { AuthService } from './core/services/auth/auth.service';
import { HttpRequestResponceInterceptor } from 'src/app/core/interceptor/http/http-request-responce.interceptor';
import { ErrorShowComponent } from './modules/error-show/error-show.component';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { MergeQuestionIdComponent } from './core/components/merge-question-id/merge-question-id.component';
import { SelectTopicComponent } from './core/components/games/select-topic/select-topic.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddMapDialogModule } from './core/components/add-map/add-map-dialog.module';
import { DuplicateMapModule } from './core/components/duplicate-map/duplicate-map.module';
import { AddVirusfreeLevelModule } from './core/components/add-virusfree-level/add-virusfree-level.module';
import { AddTextTagComponent } from './core/components/add-text-tag/add-text-tag.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SaveConditionComponent } from './core/components/save-condition/save-condition.component';
// import { AddTextComponent } from './core/components/add-text/add-text.component';
declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SnackBarLoaderComponent,
    ErrorShowComponent,
    SelectTopicComponent,
    SaveConditionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppCommonModule,
    ReactiveFormsModule,
    FormsModule,
    AddMapDialogModule,
    DuplicateMapModule,
    AddVirusfreeLevelModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    HttpClientModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthService],
      }
    })
  ],
  providers: [
    AuthService,
    InitializerService,
    {
      provide: APP_INITIALIZER,
      useFactory: initProviderFactory,
      deps: [InitializerService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestResponceInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    SnackBarLoaderComponent
  ],
})
export class AppModule { }
